$(function(){
	var startPos = 0,winScrollTop = 0;
	$(window).on('scroll',function(){
		winScrollTop = $(this).scrollTop();
		if (winScrollTop <= startPos) {
			if(winScrollTop >= 200){
				$('.menu').fadeIn(100).css('display','flex');
				$('.cart').fadeIn(100).css('display','flex');
			}
		} else {
			$('.menu').fadeOut(50);
			$('.cart').fadeOut(50);
		}
		startPos = winScrollTop;
	});
});

// gnav

$(function(){
	var _touch = ('ontouchstart' in document) ? 'touchstart' : 'click';
	$(".menu").on(_touch,function() {
		$(".menu").toggleClass("active");
		//$('.gnav__wrapper').toggleClass("on");
		$(".gnav").fadeToggle(200);
		return false;
	});

	// $(document).on(_touch,function() {
	// 	$(".menu").removeClass("active");
	// 	$(".gnav").fadeOut(200);
	// });
	// $(".menu").on(_touch,function() {
	// 	event.stopPropagation();
	// });
});



// $(function(){ 
// 	if(window.matchMedia('(max-width:767px)').matches) {
// 		$(function(){
// 			$(document).on("click", ".menu", function() {
// 				$("body").toggleClass("no-scroll");
// 				$(".menu").toggleClass("active");
// 				$(".gnav").slideToggle(100);
// 				//$("#spnav").css("display","flex");
// 				$(function(){
// 					if($(".no-scroll").length) { 
// 					  $("body").css('overflow','hidden');
// 					} else {
// 					  $("body").css('overflow','auto');
// 					}
// 				  });
// 				return false;
				
// 			});
			
// 			// $(document).click(function(event) {
// 			// 	if(!$(event.target).closest('.header').length) {
// 			// 		$("#menu").removeClass("active");
// 			// 		$("#spnav").slideUp();
// 			// 	}
// 			// });
// 			// $("#menu").on(_touch,function() {
// 			// 	event.stopPropagation();
// 			// });
// 		})
// 	}
// });

//sticky header1
// $(function() {
// 	var $win = $(window),
// 		$main = $('#main'),
// 		$nav = $('.gnav__item'),
// 		navHeight = $nav.outerHeight(),
// 		navPos = $nav.offset().top,
// 		onClass = 'on';
  
// 	$win.on('load scroll', function() {
// 	  var value = $(this).scrollTop();
// 	  if ( value > navPos ) {
// 		$nav.addClass(onClass);
// 		$('#mainnav').css('margin-top', '50px');
// 		$('#spnav').css('top', '50px');
// 	  } else {
// 		$nav.removeClass(onClass);
// 		//$main.css('margin-top', '0');
// 		$('#mainnav').css('margin-top', '0');
// 		$('#spnav').css('top', '111px');
// 	  }
// 	});
// });

//sticky header2
// $(function() {
// 	var $win = $(window),
// 		$main = $('#main'),
// 		$cta = $('#cta'),
// 		ctaHeight = $cta.outerHeight(),
// 		ctaPos = $cta.offset().top + 130,
// 		fixedClass = 'sticky';
  
// 	$win.on('load scroll', function() {
// 	  var value = $(this).scrollTop();
// 	  if ( value > ctaPos ) {
// 		$cta.addClass(fixedClass);
// 		//$('#cta').addClass('sticky');
// 	  } else {
// 		$cta.removeClass(fixedClass);
// 		//$main.css('margin-top', '0');
// 		//$('#cta').removeClass('sticky');
// 	  }
// 	});
// });

//scroll
$(function(){
	$('a[href^="#"]').click(function(){
		var speed = 500;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});
});



//slider

$(document).ready(function(){
	$('.slide').slick({
		infinite: true,
		autoplay: true,
		// slidesToShow: 1,
		// slidesToScroll: 1,
		//centerMode: true,
		arrows: false,
		dots: false,
		fade: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		speed: 1000,
	});

	
	$('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.slider-nav'
	});
	$('.slider-nav').slick({
		infinite: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		dots: false,
		arrows: false,
		//centerMode: true,
		focusOnSelect: true
	});

	// $('#gallery').slick({
	// 	infinite: true,
	// 	autoplay: true,
	// 	slidesToShow: 4,
	// 	slidesToScroll: 1,
	// 	centerMode: true,
	// 	arrows: false,
	// 	dots: false,
	// 	responsive: [
	// 		{
	// 		  breakpoint: 1024,
	// 		  settings: {
	// 			slidesToShow: 3,
	// 			slidesToScroll: 1,
	// 		  }
	// 		},
	// 		{
	// 		  breakpoint: 600,
	// 		  settings: {
	// 			slidesToShow: 2,
	// 			slidesToScroll: 2
	// 		  }
	// 		},
	// 		{
	// 		  breakpoint: 480,
	// 		  settings: {
	// 			slidesToShow: 1,
	// 			slidesToScroll: 1,
	// 		  }
	// 		}
	// 	  ]
	// });
});




//item

 	
// $(function(){ 
// 	$(function(){
// 		$(document).on("click", "#item-list__menu", function() {
// 			$(this).toggleClass("active");
// 			$(this).parents('#item-list').children('#item-nav-wrapper').slideToggle(200);
// 			return false;
// 		});
// 	})
// });


//animation

  $(function(){
	//$('.anime').css("opacity","0");
	$(window).scroll(function (){
	  $(".anime").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move');
		} 
		// else {
		//   $(this).removeClass('move');
		//   // $(this).css({ 
		//   //   "font-size": "20px",
		//   //   "padding": "20px"
		//   // });
		// }
	  });
	  //Right
	  $(".anime-r").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-r');
		}
	  });
	  //left
	  $(".anime-l").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-l');
		}
	  });
  
	  //dilay
	  $(".anime-d").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-d');
		}
	  });
	  //bottom
	  $(".anime-b").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-b');
		}
	  });
  
	});
  });